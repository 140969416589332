// Toaster
@import '~toastr/toastr';

// select2
@import "~select2/dist/css/select2.min.css";
.bg-success{background-color:#058404 !important}
.border-success{border-color:#058404 !important}
.btn-success{background-color:#058404 !important;border-color:#058404 !important}
.bg-primary-soft{background-color: rgb(119 119 119 / 10%) !important;}
.subscribe-button{background-color: #FF4500 !important;}
.text-success{color: #058505 !important}
.bg-light-custom{background-color: #f7f8f9 !important;color: #000 !important}
.bg-light-custom h3{color: #000 !important}
.lead2{
	font-size: 16px;
    font-weight: 400;
}